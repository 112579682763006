import React,{ useEffect,useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Project from "../components/Project";

function ProjectsWrap(props){
    const [sortBtnActive, setSortButtonActive] = useState("All")
    let projectTileData = props.projData.slice(1).map((element,index)=>{
        if(element[4] && (element[0] != "About Me" && element[0] != "Reach Out")){
            let images = element[4].split(",");
            let teckStak = element[1].split(',');
            return <Project key={index} dataKey={index} projectTitle={element[0]} techStack={teckStak} projectTypeTitle={element[5]} discription={element[2]} img={images} setProjectNumber={props.setProjectNumber}/>;
        }
    })
    useEffect(()=>{
        sortButton();
    },[])
    function sortButton(){
        const BUTTONS = Array.from(document.getElementsByClassName("sortButton"))
        BUTTONS.map(button => {
            button.addEventListener("click",()=>{
                try{
                    const PROJECTS_WRAP = document.getElementsByClassName("projectsWrap")[0];
                    const PROJECTS = Array.from(PROJECTS_WRAP.getElementsByClassName("singleProject"))
                    PROJECTS.map(project => {
                        const PROJECT_TYPE = project.getElementsByClassName("projectType")[0].getElementsByTagName("span")[1].textContent;
                        const PARENT = project.parentElement.parentElement.parentElement
                        if(PROJECT_TYPE != button.textContent){
                            setSortButtonActive(button.textContent);
                            PROJECTS_WRAP.getElementsByClassName("sortTitle")[0].textContent = button.textContent + " Projects";
                            PARENT.setAttribute("style","display:none;")
                            
                            PARENT.classList.add("slideInStack");
                            PARENT.classList.remove("slideIn");

                        }else{
                            PARENT.setAttribute("style","display:block;")
                            PARENT.classList.remove("slideInStack");
                            PARENT.classList.add("slideIn");
                        }
                        if(button.textContent === "All"){
                            PARENT.setAttribute("style","display:none;")
                            PARENT.classList.remove("slideIn");
                            setTimeout(()=>{
                                PARENT.classList.add("slideInStack");
                                PARENT.setAttribute("style","display:block;")
                            },0)
                        }
                    })
                    
                }catch{
                    console.log("e")
                }
            })
        })
        
            
        }
    return(
        <Container className="p-0 projectsWrap">
            <Row>
                <Col sm={12}>
                    <div className="sortTitle">All Projects</div>
                    
                </Col>
                <Col sm={12} >
                    <div className="sortWrap">
                    <button className={"btn sortButton" + (sortBtnActive === "All" ? " sortButtonActive" : "")} type="button">All</button>
                    <button className={"btn sortButton" + (sortBtnActive === "Client" ? " sortButtonActive" : "")} type="button">Client</button>
                    <button className={"btn sortButton" + (sortBtnActive === "Personal" ? " sortButtonActive" : "")} type="button">Personal</button>
                    <button className={"btn sortButton" + (sortBtnActive === "Design" ? " sortButtonActive" : "")} type="button" >Design</button>
                    </div>
                    
                </Col>
                
            </Row>
            <div className="projectTileWrap">
            <Row>
                {projectTileData}
            </Row>
            </div>
            
        </Container>
    )
}
export default ProjectsWrap;
