
import { Row, Col } from "react-bootstrap";
import "../scss/project.scss";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Project(props){
    
    let stack = props.techStack.map((element,index) =>{
        return <li key={index}>{element}</li>
    })
    let typeColorGet = function() {
        if(props.projectTypeTitle === "Client"){
            return "#DE9100"
        }
        else if(props.projectTypeTitle === "Personal"){return "#9BD6FC"}
        else if(props.projectTypeTitle === "Design"){return "#8d25f6"}
        else{return "white"}
    }
    
    let setHoverColor = (event)=>{
        try{
            const COLOR = event.currentTarget.getElementsByTagName("svg")[0].getAttribute("style").match(/\d+/g)
            event.currentTarget.setAttribute("style","background-color:rgba("
            + COLOR[0] + ","+ COLOR[1] + ","+ COLOR[2] + ",0.25);");
        }catch(e){
            console.log("can't set element background color: In setHoverColor : Component/Project.js");
        }
       
    }
    let removeHoverColor = (event)=>{
        event.currentTarget.removeAttribute("style");
    }
    let typeColor = typeColorGet();
    function projClick(){
        props.setProjectNumber(props.dataKey + 1)
        document.getElementById("bigProjectTile").scrollIntoView();
    }
    return(
        <Col sm={12 }md={6}lg={4} onClick={projClick} className={"slideInStack"}>
            <Row>
            <Col>
                    <div className="singleProject" onMouseEnter={setHoverColor} onMouseLeave={removeHoverColor}>
                        <div className="projTitleBar">
                            <Row>
                                <Col>
                                    <h4>{props.projectTitle}</h4>
                                </Col>
                                <Col>
                                    <div className="projectType">
                                        <span ><FontAwesomeIcon icon={faCircle} style={{color:typeColor}}/></span>
                                        <span>{props.projectTypeTitle}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col>
                                <div className="imgWrap">
                                    <img src={props.img[0]} alt={props.projectTitle + "Image"}/>
                                </div>
                            </Col>
                            <Col>
                                <div className="rightProjContent">
                                    <h5>Tech Stack</h5>
                                    <ul>
                                        {stack}
                                    </ul>
                                    <div className="projDisc">
                                        {/* {props.discription} */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col> 
            </Row>
        </Col>
                
    )
}