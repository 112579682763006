import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BigProjectTile from '../components/bigProjectTile';
import ProjectsWrap from '../components/Projects';
export default function Projects(props){
    
    const [projNumber, setProjNumber] = useState(1);
    const projDataa = props.projDataa;
    
    //console.log(projDataa[projNumber][1].split(","))
    let techStack = [];
    let images = [];
    if(projDataa.length > 0){
         techStack = projDataa[projNumber][1].split(",");
        images = projDataa[projNumber][4].split(",")
    }
    
  
    
    return(
        <Container className="p-0">
            <Row id="bigProjectTile">
                {projDataa.length > 0 ?
                <BigProjectTile projectTitle={projDataa[projNumber][0]} techStack={techStack} projectTypeTitle={projDataa[projNumber][5]} discription={projDataa[projNumber][2]} img={images} link={projDataa[projNumber][6]}/>
                : ""}
            </Row>
            <Row>
                <Col>
                    <ProjectsWrap projData={projDataa} setProjectNumber={setProjNumber}/>
                </Col>
            </Row>
        </Container>
    )
}