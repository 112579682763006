import '../scss/bigProject.scss';
import { faCircle, faFolderOpen, faLayerGroup, faBrain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
export default function BigProjectTile(props){
    const [hideButton, setHideButton] = useState(false);
    //hideLiveLink if no link is found
    useEffect(()=>{
        if(props.link === "n/a"){
            setHideButton(true)
        }
    });
    
    //setColors
    let typeColorGet = function() {
        if(props.projectTypeTitle === "Client"){
            return "#DE9100"
        }
        else if(props.projectTypeTitle === "Personal"){return "#9BD6FC"}
        else if(props.projectTypeTitle === "Design"){return "#8d25f6"}
        else{return "white"}
    }
    let stack = props.techStack.map((element,index) =>{
        return <li key={index}>{element}</li>
    })
    let typeColor = typeColorGet();
    function goToLink(){
        window.open(props.link,"_blank").focus();
    }
    return(
        <Col lg={12}>
            <Row >
            <Col >
                    <div key={Math.random()} className="slideIn singleProject">
                    <Row>
                        <div className="headerWrap">
                        <h2 className="aboutHeader">My Projects</h2>
                        <div className="bar"></div>
                        <div className="circle"></div>
                    </div>
                            <Col xs={12} sm={9} >
                                <h4><FontAwesomeIcon icon={faFolderOpen}/>{props.projectTitle}</h4>
                            </Col>
                            <Col >
                                <div className="projectType">
                                    <span ><FontAwesomeIcon icon={faCircle} style={{color:typeColor}}/></span>
                                    <span>{props.projectTypeTitle}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8} md={6} lg={5}>
                                <div className="imgWrap">
                                    <img src={props.img[0]} alt={props.projectTitle + "Image"}/>
                                </div>
                            </Col>
                            <Col>
                                <div className="rightProjContent">
                                    <h5><FontAwesomeIcon icon={faLayerGroup}/>Tech Stack</h5>
                                    <ul>
                                        {stack}
                                    </ul>
                                    <div className="projDisc">
                                        <h5><FontAwesomeIcon icon={faBrain}/>Reason</h5>
                                        {props.discription}
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-primary" onClick={goToLink} style={{display:hideButton === true ? "none" : ""}}>Live Site</button>
                                        <button type="button" className="btn btn-primary">More Info</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col> 
            </Row>
        </Col>
    )
}