import { Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/Logo.svg";

export default function Header(){
    return(
        <header>
            <Container>
            <Row className="justify-content-center align-center">
                <Col sm={12}className="logo"><object data={Logo}></object></Col>
                <Col sm={12} className="name"><span>Jean-Luc</span><span>Bachelot</span></Col>
                <Col sm={12} className="position"><span>Front-End Web Developer</span></Col>
            </Row>
        </Container>
        </header>
    )
}