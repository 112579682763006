import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, NavLink } from 'react-router-dom';
import React,{ useState, useEffect } from "react";
import Nav from './components/nav';
import About from './routes/about';
import Footer from './components/footer';
import Project from './routes/project';
import './scss/main.scss';
import Header from './components/header';
import Resume from './components/resume';
import BackgroundVideo from './assets/12915222_MotionElements_the-mountain-and-clouds-4k.mp4';


function App() {
  const [projDataa, setPojData] = useState([]);
  const [aboutMe, setAboutMe] = useState("JLB");
  useEffect(()=>{
    fetch("https://sheets.googleapis.com/v4/spreadsheets/1ESrI12idePYsYlBtiGKYXUsOsM3zzE9IFTTTeDadldE/values/Sheet1?alt=json&key=AIzaSyD1Ms8YKWe8-XcJY9S1oq_641px166z5Rg")
      .then(data => data.json())
      .then(results => {
        setPojData(results.values);
        console.log("call")
        
      })
      
},[]);
useEffect(()=>{
  projDataa.map(project => {
    if(project[0] === "About Me"){
      setAboutMe(project);
    }
});
})
  return (
    <BrowserRouter>
      <Container fluid>
        <Row className="h-100 justify-content-center">
          <Col sm={5} md={5} lg={4} xl={4} hxl={5} xxl={3} className="p-0 col-hxl-3 vBOlCont">
            <div id="leftContent">
              <Header/>
              <Resume />
              <Footer />
            </div>
            <div className="videoBannerOverlay"></div>
          </Col>
          <Col sm={7} md={7} lg={8} xl={7} hxl={4} xxl={5} className="p-0">
          <div id="rightContent">
              <Nav />
              <Routes>
                <Route path="/" element={<Project projDataa={projDataa}/>}></Route>
                <Route path="/about" element={<About aboutMeData={aboutMe} projDataa={projDataa}/>}></Route>
              </Routes>
            </div>
          </Col>
        </Row>
        <video autoPlay="autoPlay" loop="loop" muted className="videoBanner">
                <source className="videoBanner" src={BackgroundVideo} type="video/mp4"></source>
        </video>
      </Container>
    </BrowserRouter>
  );
}

export default App;
