import { Row,Col } from "react-bootstrap"
export default function Footer(){
    
   
    return(
        <footer className="footerWrapper">
            <Row>
                <Col sm={10}>
                    <p>
                    This site was built using react on the front end. This first Iteration Is using a google sheets as a “database”. However, this will be transferred to a .NET Backend and SQL Database. With API calls to collect the data
                    </p>
                </Col>
                <Col sm={12}>
                    <span>Jl.bachelot.com {new Date().getFullYear()}</span>
                </Col>
            </Row>
        </footer>
    )
}