import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
export default function Navi(){
    return(
        <nav>
            <NavLink to="/"><FontAwesomeIcon icon={faHome}/></NavLink>
            <NavLink className={({isActive})=>(isActive ? "navActive" : "navInactive")}to="/">Projects</NavLink>
            <NavLink className={({isActive})=>(isActive ? "navActive" : "navInactive")}to="/About">About</NavLink>
        </nav>
    )
}