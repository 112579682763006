import React, {useState} from 'react';
import { Container, Row,Col,Button } from "react-bootstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import js from '../assets/skillIcons/js.svg';
import reactLogo from '../assets/skillIcons/react.svg';
import html from '../assets/skillIcons/html5.svg';
import xd from '../assets/skillIcons/xd.svg';
import css from '../assets/skillIcons/css3.svg';
import bs from '../assets/skillIcons/bootstrap.svg';
import figma from '../assets/skillIcons/figma.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniHeader from './miniHeader';

export default function Resume(){
    const [skillsOpen, setSkillsOpen] = useState("close");
    function contactClick(){
        const POPUP = document.getElementById("contactPopUp");
        POPUP.classList.toggle("contactPopUpActive");
    }
    return(
        <div className="container">
            <Row className="skillsWrap">
                <Col xs={12}><h5>Top Skills</h5></Col>
                <Col xs={6} sm={6} md={5} lg={4} xl={4} xxl={4} className="col-hxl-4">
                    <Row>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={js}/><span>JavaScript</span></Col>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={html}/><span>HTML</span></Col>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={css}/><span>CSS</span></Col>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={bs}/><span>BootStrap</span></Col>
                    </Row>
                    
                </Col>
                <Col xs={6} sm={6} md={5} lg={4} xl={4} xxl={4} className="col-hxl-4">
                    <Row>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={reactLogo}/><span>React</span></Col>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={xd}/><span>Adobe XD</span></Col>
                    <Col xs={12} sm={12} className="my-auto skill"><img src={figma}/><span>Figma</span></Col>
                    </Row>
                </Col>
                <Col xs={11} className="moreSkills">
                    <button onClick={()=>{if(skillsOpen === "close"){setSkillsOpen("open")}else{setSkillsOpen("close")}}}>More Skills <FontAwesomeIcon icon={faAngleDown}/></button>
                    <div className={skillsOpen + " hiddenSkills"} >
                        <Row>
                            <Col>Extra skills will be added to this section soon</Col>
                        </Row>
                    </div>
                </Col>
                <Row className="buttonWrap">
                    <div id="contactPopUp">
                        <div className="popUpWrap">
                            <MiniHeader title="Contact" subheading="Abalable for contract or full-time job oppertunites"/>
                            <ul>
                                <li><b>E-Mail:</b> Jl.Bachelot@gmail.com</li>
                                <li><b>Phone:</b>  780-680-8677</li>
                            </ul>
                        </div>
                    </div>
                    <Col xs={12} sm={6} md={6} lg={6}><Button onClick={contactClick}>Contact</Button></Col>
                    <Col xs={12} sm={6} md={6} lg={6}><Button>Resume</Button></Col>
                </Row>
            </Row>
        </div>
    )
}