import { Container,Row,Col } from "react-bootstrap";
import '../scss/aboutMe.scss';
import ahsLogo from '../assets/ahsLogo.png';
import autoCanLogo from '../assets/AcLogo.webp';
import MiniHeader from "../components/miniHeader";
export default function About(props){
    let aboutMeTitle = props.aboutMeData[0];
    let aboutMeImage = props.aboutMeData[4];
    let aboutMeText = props.aboutMeData[3]
    return(
        
        <Container>
            <MiniHeader title="About" subheading="Jean-Luc Bachelot : Front-End Web Developer"/>
            <Row>
                
                <Col md={8}>
                    <div className="aboutMeImageWrap">
                        <img src={aboutMeImage} alt="coffee at Lake Louise"/>
                    </div>
                </Col>
                <Col md={4} sm={12} className="aboutMeCol">
                    <div className="aboutMeTextWrap">
                        <h5>{aboutMeTitle}</h5>
                        <p>
                            {aboutMeText}
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}